<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonSearchPageInfo.label_page_title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search
            class="top-nav-search"
            :showDescriptionText="showDescriptionText"
            :backLabel="gideonSearchPageInfo.label_back_description"
            :i18n="translations.components"
          >
          </top-nav-search>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-details mb-5" v-if="isToggled">
          <data-table
            v-if="translations.components"
            :title="gideonSearchPageInfo.name"
            :tooltip="tooltip"
            :fields="dataTableFields"
            :items="tableItems"
            :toggle="showToggle"
            :noRecordsMessage="gideonSearchPageInfo.label_NoRecordsFound"
            :i18n="translations.components"
            @addSearchItem="addItemToCart"
          ></data-table>
          <!-- / Data Tables -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTableGideonStore.vue'
import { securityMixin } from '@/mixins/securityMixin'
import TopNavSearch from './TopNavSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-search',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      dataTableFields: [],
      isToggled: true,
      storeLPPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Search',
      },
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      storeSearchPayload: {
        lang: 'en', // default to english for now
        criteria: '',
        product_type: 'all',
      },
      showDescriptionText: false,
      showToggle: true,
      tooltip: 'Testing',
      translations: {},
      scripture_accessible: false,
      secured_store_view_controls: {
        store_scripture_button: 'dd37ad10-32f0-4607-833f-8b6c41d22bc0',
        store_merchandise_button: 'ec511c9b-8a0c-49b4-91f4-0d2622b3147e',
      },
    }
  },
  components: {
    topNavSearch: TopNavSearch,
    dataTable: DataTable,
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      loadStoreProductSearch: 'gideonStore/loadStoreProductSearch',
      loadStoreSearchPage: 'gideonStore/loadStoreSearchPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateStoreCart: 'gideonStore/updateStoreCart',
    }),
    async page_load() {
      this.setLoadingStatus(true), (this.storeNavPagePayload.lang = this.prefCulture)
      this.storeLPPayload.lang = this.prefCulture
      this.storeSearchPayload.lang = this.prefCulture
      this.storeSearchPayload.criteria = this.gideonStoreSearchText
      await Promise.all([
        this.getViewTranslations(),
        await this.getComponentTranslations('data-table-store', 'store-topnav-search'),
        await this.checkPermissions(),
        await this.loadStoreSearchPage(this.storeLPPayload),
        await this.loadStoreProductSearch(this.storeSearchPayload),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)

        const { tcItem, tcPrice, tcProduct, tcType } = this.translations
        this.dataTableFields = [
          { key: 'prd_name', label: tcProduct, sortable: true },
          { key: 'prd_code_normalized', label: tcItem, sortable: true },
          {
            key: 'prd_display_price',
            label: tcPrice,
            sortable: true,
            formatter: (value, key, item) => '$' + Number(value).toFixed(2),
          },
          { key: 'TemplateName', label: tcType, sortable: true },
          {
            key: 'prd_key',
            label: 'Options',
            sortable: false,
            thStyle: {
              display: 'none',
            },
            tdStyle: {
              'text-align': 'center',
            },
          },
        ]
        this.setLoadingStatus(false)
      })
    },
    async checkPermissions() {
      await this.getControlPermissions(this.secured_store_view_controls.store_scripture_button).then(() => {
        this.scripture_accessible = this.determineAccessibility(this.secured_store_view_controls.store_scripture_button)
      })
    },
    async addItemToCart(data) {
      let cartPayload = {
        prd_key: data,
        quantity: 1,
      }
      let response = false
      await Promise.all([
        (response = await this.updateStoreCart(cartPayload)),
        await this.loadStoreNavigationPage(this.storeNavPagePayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingItem || 'There was an error adding the item to the cart.',
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      }
      this.$router.push({ name: 'store-cart' })
    },
  },
  computed: {
    ...mapGetters({
      gideonSearchPageInfo: 'gideonStore/gideonSearchPageInfo',
      gideonSearchProducts: 'gideonStore/gideonSearchProducts',
      gideonStoreSearchText: 'gideonStore/gideonStoreSearchText',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
    }),
    tableItems() {
      try {
        const accessible_products = this.scripture_accessible
          ? this.gideonSearchProducts.Products
          : this.gideonSearchProducts.Products.filter((spf) => !spf.TemplateName.includes('Scripture'))
        return accessible_products.map((x) => {
          return {
            ...x,
            prd_code_normalized: x.prd_code.replace(/[^\d.]/g, '0').padEnd(10, '0'),
          }
        })
      } catch (e) {
        console.error(e)
        return []
      }
    },
  },
  created() {
    this.page_load()
  },
  watch: {
    gideonStoreSearchText: function () {
      this.page_load()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}
.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}
.switch-buttons {
  margin: 0 auto;
  text-align: center;
  .merchscriptBtn {
    background-color: #747474;
  }
  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}
.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    .tile-col-card {
      flex-grow: 2;
      .icon {
        min-height: 75px;
      }
    }
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
